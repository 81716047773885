<template>
    <v-app id="inspire">
        <sidebar :visible.sync="drawer" />
        <head-bar @toggle-sidebar="drawer = !drawer" />
        <v-main>
            <v-container fluid>
            	<v-row align="start" justify="start" dark>
        			<v-col cols="12">
            			<v-breadcrumbs
                            :items="breadcrumbs"
                            class="px-0 py-2"
					    >
                            <template #item="{ item }">
                                <v-breadcrumbs-item exact :disabled="item.disabled" :to="item.to">
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
					    <v-divider class="my-2"></v-divider>
            		</v-col>
            	</v-row>
                <router-view v-if="online" :key="$route.path" />
                <offline-warning v-else />
            </v-container>
        </v-main>
        <snack-bar />
    </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Sidebar from '@/components/template/Sidebar/Index.vue'
import HeadBar from '@/components/template/HeadBar.vue'
import OfflineWarning from '@/components/template/OfflineWarning.vue'
import SnackBar from '@/components/template/SnackBar.vue'

export default {
    components: {
        Sidebar,
        HeadBar,
        OfflineWarning,
        SnackBar
    },
    data () {
        return {
            drawer: true
        }
    },
    computed: {
        ...mapState('app', ['online']),
    	breadcrumbs() {
    		return _.map(_.get(this.$route, 'meta.breadcrumbs', []), (item) => {
                const id = _.get(this, '$route.params.id', 0);
                _.set(item, 'to', _.replace(item.to, ':id', id));
                return item;
            });
    	}
    }
};
</script>