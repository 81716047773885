<template>
    <v-snackbar
        v-model="show"
        :timeout="timeout"
    >
        {{ text }}
        <template v-if="closeBtn" v-slot:action="{ attrs }">
            <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="show = false"
            >
                Закрыть
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>

const defaultTimeout = 3000

export default {
    data () {
        return {
            show: false,
            text: null,
            timeout: null,
            closeBtn: false
        }
    },
    mounted () {
        this.$root.$on('snack-bar', this.activate)
    },
    beforeDestroy() {
        this.$root.$off("snack-bar", this.activate)
    },
    methods: {
        async activate (settings) {

            if (this.show) {
                this.show = false
                await this.$nextTick()
            }

            this.text = settings.text || ''
            this.timeout = settings.timeout || defaultTimeout
            this.closeBtn = settings.closeBtn || false
            this.show = true
        }
    }
}
</script>