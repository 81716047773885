<template>
    <v-alert
        type="warning"
    >
        <span>Отсутствует подключение к серверу</span>
        <v-btn
            small
            :color="$const.color.primary"
            class="ml-4"
            @click="location.reload()"
        >Повторить попытку</v-btn>
    </v-alert>
</template>

<script>
export default {
    computed: {
        location () { return window.location }
    }
}
</script>