<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="purple darken-2" dark>
        <v-app-bar-nav-icon @click.prevent.stop="toggleSidebar"></v-app-bar-nav-icon>
        <v-toolbar-title class="ml-0 pl-4">
            <span class="hidden-sm-and-down">{{ projectName }}</span>
        </v-toolbar-title>
        <div class="d-flex align-end h-50 ml-2">
            <span v-if="!loading && items.length" class="text-caption hidden-sm-and-down">{{ getLicensesStatusInfo(items) }}</span>
        </div>
        <v-spacer></v-spacer>
        <span v-if="profile">{{ profile.name || profile.login }}</span>
        <v-btn icon @click.prevent.stop="dialogs.logout = true">
            <v-icon>mdi-logout-variant</v-icon>
        </v-btn>

        <v-dialog v-model="dialogs.logout" max-width="500px">
            <v-card>
                <v-card-title class="headline">Вы действительно хотите выйти из учётной записи?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="purple darken-2" @click="dialogs.logout = false">Отмена</v-btn>
                    <v-btn dark color="primary" outlined @click="logout">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'HeadBar',
    computed: {
        ...mapState('user', ['profile']),
        ...mapState('license', ['items', 'loading']),
        projectName: () => process.env.VUE_APP_PROJECT_NAME
    },
    data () {
        return {
            dialogs: {
                logout: false
            }
        }
    },
    methods: {
        toggleSidebar() {
            this.$emit('toggle-sidebar', 1);
        },
        async logout() {
            await this.$store.dispatch('fisoko/logout');
            await this.$store.dispatch('user/logout');
            this.$router.go('/');
        },
        getLicensesStatusInfo(licenses) {
            if (!licenses) { return; }

            for (const license of licenses)
                if (this.isLicenseActive(license) && !this.isLicenseExpiresSoon(license))
                    return 'Активировано';

            for (const license of licenses)
                if (!this.isLicenseExpired(license) && this.isLicenseExpiresSoon(license))
                    return 'Активировано. Срок действия почти истек.';

            return 'Нет активированных лицензий';
        },
        isLicenseExpiresSoon(item) {
            const now = parseInt(Date.now() / 1000);
            const monthInSeconds = 60 * 60 * 24 * 31;
            return now + monthInSeconds > item.license.expires_at;
        },
        isLicenseExpired(item) {
            const now = parseInt(Date.now() / 1000);
            return now > item.license.expires_at;
        },
        isLicenseActive(item) {
            if (!item.license.active) { return false; }
            return !this.isLicenseExpired(item);
        }
    }
}
</script>